// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-blog-archive-js": () => import("./../../../src/templates/blog-archive.js" /* webpackChunkName: "component---src-templates-blog-archive-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-design-archive-js": () => import("./../../../src/templates/design-archive.js" /* webpackChunkName: "component---src-templates-design-archive-js" */),
  "component---src-templates-design-category-js": () => import("./../../../src/templates/design-category.js" /* webpackChunkName: "component---src-templates-design-category-js" */),
  "component---src-templates-design-post-js": () => import("./../../../src/templates/design-post.js" /* webpackChunkName: "component---src-templates-design-post-js" */),
  "component---src-templates-illustration-archive-js": () => import("./../../../src/templates/illustration-archive.js" /* webpackChunkName: "component---src-templates-illustration-archive-js" */),
  "component---src-templates-illustration-category-js": () => import("./../../../src/templates/illustration-category.js" /* webpackChunkName: "component---src-templates-illustration-category-js" */),
  "component---src-templates-illustration-post-js": () => import("./../../../src/templates/illustration-post.js" /* webpackChunkName: "component---src-templates-illustration-post-js" */)
}

